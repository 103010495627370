import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import AdminUserForm from "./AdminUserForm";
import { updateAdminUser } from "service/api/adminUser";
import { PUBLIC_STATUS } from "commons/const";
import { getAdminUserDetail } from "service/api/adminUser";
import { Validate } from "commons/tValidate";

export default function AdminUserEditPage() {
  const dispatch = useDispatch();
  const { adminUserId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    adminUser: {
      name: "",
      email: "",
      status: PUBLIC_STATUS,
      role: "admin",
    },
  });
  const { adminUser } = getState;

  const onSave = useCallback(
    (request) => {
      return updateAdminUser(adminUserId, request);
    },
    [adminUserId]
  );

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_022));
    navigate(Path.adminUser);
  }, [dispatch]);

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !adminUserId) {
      return cleanup;
    }
    getAdminUserDetail(adminUserId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, adminUser: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError("NoExist"));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.adminUser, { state: location });
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, adminUserId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return (
    <AdminUserForm
      title="Edit User"
      initialValues={adminUser}
      onSave={onSave}
      onSuccess={onSuccess}
      isAddScreen={false}
    />
  );
}

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import { Validate } from "commons/tValidate";
import { updateFaq, getFaqDetail } from "service/api/faq";
import FaqForm from "./FaqForm";

export default function FaqEditPage() {
  const dispatch = useDispatch();
  const { faqId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    faq: {
      question: "",
      answer: "",
      status: false,
    },
  });
  const { faq } = getState;

  const onSave = useCallback(
    (request) => {
      return updateFaq(faqId, request);
    },
    [faqId]
  );

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_022));
    navigate(Path.faq);
  }, [dispatch]);

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !faqId) {
      return cleanup;
    }
    getFaqDetail(faqId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, faq: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError(Validate.MSG_046));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.faq);
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, faqId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return (
    <FaqForm
      title="Edit FAQ"
      initialValues={{ ...faq, status: faq.status == 1 ? true : false }}
      onSave={onSave}
      onSuccess={onSuccess}
      readonly={false}
      isAddScreen={false}
    />
  );
}

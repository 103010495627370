import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { PER_PAGE } from "commons/const";
// Data
import {
  Box,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { TEXT_LINE_CLAMP } from "theme/themeConst";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getSortDirection } from "utils/search";
import { getPageLimit } from "utils/search";
import { listAdminUser } from "service/api/adminUser";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import NoData from "components/NoData";
import HeaderSorted from "components/HeaderSorted";
import CustomPagination from "components/CustomPagination";
// import SimpleBadge from "components/SimpleBadge";
import { PUBLIC_STATUS, NOT_PUBLIC } from "commons/const";
import routes from "route/routes";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { useDispatch } from "react-redux";
import { deleteAdminUser } from "service/api/adminUser";
import { showError, showSuccess } from "../../redux/commonReducer";
import { updateAdminUser } from "service/api/adminUser";
import SearchIcon from "@mui/icons-material/Search";
import Path from "route/Path";
import SearchControl from "components/Form/SearchControl";
import moment from "moment";

export default function AdminUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    keyword: "",
    status: "",
    sort_field: "id",
    sort: "asc",
    page: "1",
    per_page: PER_PAGE,
  });

  const [searchState, setSearchState] = useState({
    error: "",
    rows: [],
    total: 0,
    totalPages: 0,
  });

  const [deleteState, setDeleteState] = useState({
    loading: false,
    open: false,
    admin_user: {},
  });

  const [updateState, setUpdateState] = useState({
    loading: false,
    open: false,
    admin_user: {},
    data_update: {},
  });

  const createNewSearchParams = (values) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("sort_field", values.sortField || "");
    newSearchParams.set("sort", values.sort || "");
    newSearchParams.set("keyword", values.keyword || "");
    newSearchParams.set("status", `${values.status}` || "");
    newSearchParams.set("per_page", `${values.perPage}`);
    return newSearchParams;
  };

  const onSubmit = (values, helpers) => {
    const newSearchParams = createNewSearchParams(values);
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      keyword: searchParams.get("keyword") || "",
      status: searchParams.get("status") || "",
      loading: true,
      sortField: searchParams.get("sort_field") || "id",
      sort: getSortDirection(searchParams.get("sort")),
      page: Number(searchParams.get("page")) || 1,
      perPage: getPageLimit(searchParams.get("per_page")),
    },
    onSubmit,
    enableReinitialize: true,
  });

  const onClearSearch = () => {
    setFieldValue("keyword", "");
    setFieldValue("status", "");
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("keyword", "");
    newSearchParams.set("status", "");
    setSearchParams(newSearchParams);
  };

  const onSortChange = (field) => {
    const newSearchParams = new URLSearchParams(searchParams);
    let direction = "asc";
    if (values.sortField === field) {
      direction = values.sort === "asc" ? "desc" : "asc";
    }
    newSearchParams.set("sort_field", field);
    newSearchParams.set("sort", direction);
    setSearchParams(newSearchParams);
  };

  // Delete
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!deleteState.loading || !deleteState.admin_user.id) {
      return cleanup;
    }
    deleteAdminUser(deleteState.admin_user.id)
      .then((res) => {
        if (!mounted) return;
        setDeleteState((v) => ({ ...v, loading: false }));
        // if (res.data.lstDeleted) {
        dispatch(showSuccess("Deleted successfully."));
        // }
      })
      .catch((e) => {
        console.log(e);
        if (!mounted) return;
        setDeleteState((v) => ({ ...v, loading: false }));
        dispatch(showError("Deleted Failed"));
      })
      .finally(() => {
        setFieldValue("loading", true);
      });
    return cleanup;
  }, [deleteState.loading, deleteState.admin_user, dispatch, setFieldValue]);

  // Update
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!updateState.loading || !updateState.admin_user.id) {
      return cleanup;
    }
    updateAdminUser(updateState.admin_user?.id, updateState.data_update)
      .then(() => {
        if (!mounted) return;
        dispatch(showSuccess("Updated successfully."));
        setFieldValue("loading", true);
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.msg === "scheduleGreaterNow") {
          dispatch(showError("scheduleGreaterNow"));
        }
      });
    return cleanup;
  }, [dispatch, updateState.loading, updateState.admin_user, setFieldValue]);

  // List
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!values.loading) {
      return cleanup;
    }
    listAdminUser(values)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setSearchState({
          error: "",
          total: data.total,
          totalPages: data.totalPages,
          rows: data.row,
        });
        setFieldValue("loading", false);
      })
      .catch((e) => {
        if (!mounted) return;
        setSearchState({
          error: e.message,
          rows: [],
          total: 0,
          totalPages: 0,
        });
        setFieldValue("loading", false);
      });
    return cleanup;
  }, [setFieldValue, values]);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={6} md={11}>
          <Typography fontWeight="bold">Admin Users</Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="button"
            onClick={() => navigate(Path.adminUserAdd)}
          >
            Add new
          </MDButton>
        </Grid>
      </Grid>

      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "flex-end",
          // justifyContent: "space-between",
          mx: -1,
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <SearchControl
          sx={{ width: "30%", flexShrink: 0, px: 1 }}
          label="Search"
          control={
            <TextField
              fullWidth
              value={values.keyword}
              placeholder="Name or email"
              onChange={(e) => setFieldValue("keyword", e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set("keyword", `${e.target.value.trim()}`);
                  newSearchParams.set("status", `${values.status}`);
                  newSearchParams.set("sort_field", `id`);
                  newSearchParams.set("sort", `asc`);
                  newSearchParams.set("page", `1`);
                  newSearchParams.set("per_page", PER_PAGE);
                  setSearchParams(newSearchParams);
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        <FormControl sx={{ width: "20%" }}>
          <SearchControl
            sx={{ width: "100%", flexShrink: 0, px: 1, mr: 1 }}
            mr={1}
            label="Status"
            control={
              <Select
                displayEmpty
                value={values.status}
                onChange={(e) => setFieldValue("status", e.target.value)}
                sx={values.status === "" ? { py: "0.75rem", color: "#ccc" } : { py: "0.75rem" }}
              >
                <MenuItem sx={{ color: "#ccc" }} disabled value="">
                  Please select
                </MenuItem>
                <MenuItem value=" ">All</MenuItem>
                <MenuItem value={PUBLIC_STATUS}>Active</MenuItem>
                <MenuItem value={NOT_PUBLIC}>Inactive</MenuItem>
              </Select>
            }
          />
        </FormControl>
        <Box mr={2}>
          <MDButton
            sx={{ with: "20%", py: "0.75rem" }}
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => onClearSearch()}
          >
            Reset
          </MDButton>
        </Box>
        <Box>
          <MDButton
            sx={{ with: "20%", py: "0.75rem" }}
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("status", `${values.status}`);
              newSearchParams.set("keyword", `${values.keyword.trim()}`);
              setSearchParams(newSearchParams);
            }}
          >
            Search
          </MDButton>
        </Box>
      </Box>
      <Box sx={{ marginTop: 5 }}>
        {/* <Card> */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: 50 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label="ID"
                    sortBy="id"
                    handleChangeSorted={() => onSortChange("id")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 200 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label="Name"
                    sortBy="name"
                    handleChangeSorted={() => onSortChange("name")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 400 }}>
                  Email
                </TableCell>
                <TableCell align="center" sx={{ width: 400 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label="Created Date"
                    sortBy="created_at"
                    handleChangeSorted={() => onSortChange("created_at")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label="Status"
                    sortBy="status"
                    handleChangeSorted={() => onSortChange("status")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 300 }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                if (values.loading) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }

                if (!searchState.rows.length) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <NoData />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
                return searchState.rows.map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">
                      <Link href="#" onClick={() => navigate(Path.adminUserDetail(item.id))}>
                        <Box
                          sx={{
                            ...TEXT_LINE_CLAMP(),
                            "&:hover": {
                              color: "blue",
                              textDecorationLine: "underline",
                            },
                          }}
                        >
                          {item.name}
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ ...TEXT_LINE_CLAMP() }}>{item.email}</Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ ...TEXT_LINE_CLAMP() }}>
                        {moment(item.created_at).format("MMM DD YYYY")}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <MDBox ml={-1}>
                          <MDBadge
                            badgeContent={item.status === PUBLIC_STATUS ? "Active" : "Inactive"}
                            color={item.status === PUBLIC_STATUS ? "success" : "secondary"}
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        sx={{ mx: 0.5 }}
                        onClick={() => navigate(Path.adminUserEdit(item.id))}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ mx: 0.5 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteState((v) => ({
                            ...v,
                            open: true,
                            admin_user: item,
                          }));
                        }}
                      >
                        <Icon fontSize="small">delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ));
              })()}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          loading={values.loading}
          page={values.page}
          totalPage={Math.ceil(searchState.total / values.perPage)}
          handleChangePage={(changeValue) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set("page", `${values.page + changeValue}`);
            setSearchParams(newSearchParams);
          }}
        />
      </Box>

      <ConfirmDialog
        open={deleteState.open}
        onClose={() => setDeleteState((v) => ({ ...v, open: false }))}
        title="Delete user"
        message="Are you sure you want to delete this user?"
        cancelText="cancel"
        confirmText="delete"
        onConfirm={() => setDeleteState((v) => ({ ...v, open: false, loading: true }))}
      />
    </DashboardLayout>
  );
}

/* eslint-disable class-methods-use-this */
import client from "./client";
import CommonService from "./CommonService";

class AuthService {
  login(data) {
    return CommonService.postModel("admin/sign-in", data);
  }
}

export default new AuthService();

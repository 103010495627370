import { ReactElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function SearchControl({ label, control, required, sx }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", ...(sx || {}) }}>
      <Typography variant="body2" sx={{ mb: 0.5 }}>
        {label}
        {required && (
          <Typography component="span" sx={{ color: "red.500", ml: 0.5 }}>
            *
          </Typography>
        )}
      </Typography>
      {control}
    </Box>
  );
}

export default SearchControl;

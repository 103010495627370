import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import { LIST_CURRENTCY } from "commons/const";

export default function ServiceFeeDialog({
  title,
  handleSubmit,
  values,
  errors,
  touched,
  setFieldValue,
  submitting,
  onCancel,
  addText,
  cancelText,
  ...rest
}) {
  return (
    <Dialog {...rest} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <Typography fontWeight="bold" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2 }}
        >
          <Box component="div" onSubmit={handleSubmit} alignItems="center" sx={{ display: "flex" }}>
            <Typography sx={{ width: "30%", fontSize: "16px" }}>Transaction Type</Typography>
            <TextField
              sx={{ width: "70%" }}
              name="transaction_type"
              inputProps={{ style: { textAlign: "center" } }}
              disabled
              value={values.transaction_type}
            />
          </Box>
          <Box
            component="div"
            onSubmit={handleSubmit}
            justifyItems="center"
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography sx={{ width: "30%", fontSize: "16px" }}>Service Type Fee</Typography>

            <FormControl sx={{ width: "70%" }}>
              <Select
                displayEmpty
                value={values.service_fee_type}
                onChange={(e) =>
                  setFieldValue({
                    ...values,
                    service_fee_type: e.target.value,
                    currency_code: e.target.value == "Percentage" ? "%" : "JPY",
                  })
                }
                sx={
                  values.service_fee_type === ""
                    ? { py: "0.75rem", color: "#ccc" }
                    : { py: "0.75rem" }
                }
              >
                <MenuItem disabled value="Fixed">
                  Fixed
                </MenuItem>
                <MenuItem value="Percentage">Percentage</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            component="div"
            onSubmit={handleSubmit}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography sx={{ width: "30%", fontSize: "16px" }}>Service Value</Typography>
            <Box sx={{ width: "70%", display: "flex", flexDirection: "row" }}>
              <TextField
                sx={{ width: "60%" }}
                name="service_value"
                value={values.service_value}
                onChange={(e) => {
                  let newValue = e.target.value;
                  // Allow only numbers between 0 and 100 with up to 3 decimal places
                  const regex = /^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/;
                  if (regex.test(newValue)) {
                    setFieldValue({
                      ...values,
                      service_value: newValue,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === "-" || e.key === "e") {
                    e.preventDefault();
                  }
                }}
                error={touched.service_value && Boolean(errors.service_value)}
                helperText={touched.service_value && errors.service_value}
                type="number"
              />
              <Box justifyContent="flex-end" display="flex" sx={{ width: "40%" }}>
                {values.service_fee_type == "Fixed" ? (
                  <FormControl sx={{ width: "60%" }}>
                    <Select
                      displayEmpty
                      value={values.currency_code}
                      onChange={(e) => setFieldValue({ ...values, currency_code: e.target.value })}
                      sx={{ py: "0.75rem" }}
                      MenuProps={{ sx: { maxHeight: "300px", maxWidth: "25%" } }}
                    >
                      {LIST_CURRENTCY.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    sx={{ width: "60%" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    name="service_value"
                    value="%"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {submitting ? (
          <LoadingButton
            loading={submitting}
            type="button"
            variant="contained"
            sx={{ ml: 3, minWidth: 50 }}
            onClick={() => {}}
          >
            Save
          </LoadingButton>
        ) : (
          <MDButton
            type="button"
            variant="contained"
            color="info"
            sx={{ ml: 3, minWidth: 50 }}
            onClick={() => handleSubmit()}
          >
            Save
          </MDButton>
        )}
        {/* <LoadingButton
          loading={submitting}
          variant=""
          color="info"
          sx={!onSave ? { display: "none" } : null}
          onClick={() => handleSubmit()}
        >
          {addText}
        </LoadingButton> */}
        {/* <Button
          variant="gradient"
          onClick={onCancel || ((e) => rest.onClose?.(e, "backdropClick"))}
        >
          {cancelText}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

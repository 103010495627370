import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { PER_PAGE } from "commons/const";
// Data
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getSortDirection } from "utils/search";
import { getPageLimit } from "utils/search";
import NoData from "components/NoData";
import HeaderSorted from "components/HeaderSorted";
import CustomPagination from "components/CustomPagination";
import { useDispatch } from "react-redux";
import { list } from "service/api/transaction";
import SearchIcon from "@mui/icons-material/Search";
import Path from "route/Path";
import SearchControl from "components/Form/SearchControl";
import moment from "moment";

export default function Transaction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    keyword: "",
    sort_field: "id",
    sort: "desc",
    page: "1",
    per_page: PER_PAGE,
  });

  const [searchState, setSearchState] = useState({
    error: "",
    rows: [],
    total: 0,
    totalPages: 0,
  });

  const createNewSearchParams = (values) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("sort_field", values.sortField || "");
    newSearchParams.set("sort", values.sort || "");
    newSearchParams.set("keyword", values.keyword || "");
    newSearchParams.set("status", `${values.status}` || "");
    newSearchParams.set("per_page", `${values.perPage}`);
    return newSearchParams;
  };

  const onSubmit = (values, helpers) => {
    const newSearchParams = createNewSearchParams(values);
    newSearchParams.set("page", "1");
    if (isSameSearchParams(searchParams, newSearchParams)) {
      if (values.page !== 1) {
        helpers.setFieldValue("page", 1);
      }
      helpers.setFieldValue("loading", true);
    } else {
      setSearchParams(newSearchParams);
    }
  };

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      keyword: searchParams.get("keyword") || "",
      status: searchParams.get("status") || "",
      loading: true,
      sortField: searchParams.get("sort_field") || "created_at",
      sort: getSortDirection(searchParams.get("sort")),
      page: Number(searchParams.get("page")) || 1,
      perPage: getPageLimit(searchParams.get("per_page")),
    },
    onSubmit,
    enableReinitialize: true,
  });

  const onClearSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("keyword", "");
    newSearchParams.set("status", "");
    setSearchParams(newSearchParams);
  };

  const onSortChange = (field) => {
    const newSearchParams = new URLSearchParams(searchParams);
    let direction = "asc";
    if (values.sortField === field) {
      direction = values.sort === "asc" ? "desc" : "asc";
    }
    newSearchParams.set("sort_field", field);
    newSearchParams.set("sort", direction);
    setSearchParams(newSearchParams);
  };

  // List
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!values.loading) {
      return cleanup;
    }
    list(values)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setSearchState({
          error: "",
          total: data.total,
          totalPages: data.totalPages,
          rows: data.row,
        });
        setFieldValue("loading", false);
      })
      .catch((e) => {
        if (!mounted) return;
        setSearchState({
          error: e.message,
          rows: [],
          total: 0,
          totalPages: 0,
        });
        setFieldValue("loading", false);
      });
    return cleanup;
  }, [setFieldValue, values]);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={6} md={11}>
          <Typography fontWeight="bold">Wallet Address List</Typography>
        </Grid>
      </Grid>

      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "flex-end",
          // justifyContent: "space-between",
          mx: -1,
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <SearchControl
          sx={{ width: "30%", flexShrink: 0, px: 1 }}
          label=""
          control={
            <TextField
              fullWidth
              value={values.keyword}
              placeholder="Enter Wallet Address ID"
              onChange={(e) => {
                setFieldValue("keyword", e.target.value);
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("keyword", `${e.target.value.trim()}`);
                setSearchParams(newSearchParams);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      </Box>
      <Box sx={{ marginTop: 5 }}>
        {/* <Card> */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: 50 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label="No"
                    sortBy=""
                    handleChangeSorted={() => onSortChange("id")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 300 }}>
                  Created Date
                </TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  UserId
                </TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  Network
                </TableCell>
                <TableCell align="center" sx={{ width: 700 }}>
                  <HeaderSorted
                    valueSortBy={values.sortField}
                    valueOrderBy={values.sort}
                    label=" Wallet Address ID"
                    sortBy="wallet_address"
                    handleChangeSorted={() => onSortChange("wallet_address")}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: 150 }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                if (values.loading) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }

                if (!searchState.rows.length) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <NoData />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
                return searchState.rows.map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="left">
                      {moment(item.created_at).format("MMM DD YYYY")}
                    </TableCell>
                    <TableCell align="center">{item.user_id}</TableCell>
                    <TableCell align="center">{item.network_code}</TableCell>
                    <TableCell align="left">{item.wallet_address}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        sx={{ mx: 0.5 }}
                        onClick={() =>
                          navigate(Path.transactionByWalletAdressSend(item.wallet_address))
                        }
                      >
                        <Icon fontSize="small">visibility</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ));
              })()}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          loading={values.loading}
          page={values.page}
          totalPage={Math.ceil(searchState.total / values.perPage)}
          handleChangePage={(changeValue) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set("page", `${values.page + changeValue}`);
            setSearchParams(newSearchParams);
          }}
        />
      </Box>
    </DashboardLayout>
  );
}

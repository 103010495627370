/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Typography, Select, MenuItem } from "@mui/material";
import { showError } from "../../../src/redux/commonReducer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import RequiredAsterisk from "components/RequiredAsterisk";
import { Validate } from "commons/tValidate";
import Switch from "@mui/material/Switch";
import Path from "route/Path";

function NotificationForm({
  readonly = false,
  title,
  initialValues,
  onSave,
  onSuccess,
  isAddScreen = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);

  const [submitState, setSubmitState] = useState({
    loading: false,
    values: undefined,
  });
  const validationParam = {
    title: Yup.string().trim().required(Validate.MSG_001).max(255, Validate.MSG_039(255)),
    message: Yup.string().trim().required(Validate.MSG_001).max(500, Validate.MSG_039(500)),
  };

  const validationSchema = Yup.object().shape(validationParam);

  const onSubmit = (values) => {
    setSubmitState((v) => ({ ...v, loading: true, values }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldError, setSubmitting } =
    formik;

  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!submitState.loading || !submitState.values) {
      return cleanup;
    }

    onSave?.({ ...submitState.values, status: submitState.values.status ? 1 : 0 })
      .then((res) => {
        if (res) {
          setSubmitState((v) => ({ ...v, loading: false }));
          onSuccess();
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(showError(Validate.MSG_032));
        setSubmitState((v) => ({ ...v, loading: false }));
      });
    return cleanup;
  }, [
    dispatch,
    onSave,
    onSuccess,
    setFieldError,
    setSubmitting,
    submitState.loading,
    submitState.values,
    values.contents,
  ]);
  return (
    <DashboardLayout>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <Box mt={5} mb={5}>
          <Box display="flex" my={3}>
            <Box width="100%" sx={{ display: "flex" }}>
              <Typography width="20%">
                Title
                <RequiredAsterisk />
              </Typography>
              <Box width="80%">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 255, readOnly: readonly }}
                  placeholder="Enter title"
                  value={values.title}
                  onChange={(e) => setFieldValue("title", e.target.value)}
                  error={Boolean(errors?.title && touched?.title)}
                  helperText={errors?.title && touched?.title ? errors?.title : ""}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" my={3}>
            <Box width="100%" sx={{ display: "flex" }}>
              <Typography width="20%">
                Message
                <RequiredAsterisk />
              </Typography>
              <Box width="80%">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 500, readOnly: readonly }}
                  placeholder="Enter message"
                  value={values.message}
                  onChange={(e) => setFieldValue("message", e.target.value)}
                  error={Boolean(errors?.message && touched?.message)}
                  helperText={errors?.message && touched?.message ? errors?.message : ""}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" my={3}>
            <Box width="100%" sx={{ display: "flex" }}>
              <Typography width="20%">Status</Typography>
              <Switch
                disabled={readonly}
                checked={values.status}
                onChange={(e) => setFieldValue("status", e.target.checked)}
                // inputProps={{ "aria-label": "controlled" }}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <MDButton
            type="button"
            variant="outlined"
            color="info"
            sx={{ minWidth: 150 }}
            onClick={() => navigate(-1)}
          >
            Back
          </MDButton>
          {!readonly ? (
            submitState.loading ? (
              <LoadingButton
                loading={submitState.loading}
                type="button"
                variant="contained"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </LoadingButton>
            ) : (
              <MDButton
                type="submit"
                variant="contained"
                color="info"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </MDButton>
            )
          ) : (
            <MDButton
              type="button"
              variant="contained"
              color="info"
              sx={{ ml: 3, minWidth: 150 }}
              onClick={() => {
                navigate(Path.notificationEdit(values.id));
              }}
            >
              Edit
            </MDButton>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default NotificationForm;

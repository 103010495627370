import CommonService from "./CommonService";
import client from "./client";

export const updateLegalDocument = (id, data) => {
  return CommonService.updateModel(`/cms/legal-document/update/${id}`, data);
};

export const getLegalDocument = (id) => {
  return CommonService.getModelList(`/cms/legal-document/detail/${id}`);
};

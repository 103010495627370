import CommonService from "./CommonService";
import client from "./client";

export const listNotification = (params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
    keyword: String(params.keyword),
    status: String(params.status).trim(),
    order_by: String(params.sortField),
    sort_by: String(params.sort),
  };
  return CommonService.getModelList("/cms/notification", new URLSearchParams(searchParam));
};

export const createNotification = (data) => {
  return CommonService.postModel(`/cms/notification/create`, data);
};

export const updateNotification = (id, data) => {
  return CommonService.updateModel(`/cms/notification/update/${id}`, data);
};

export const deleteNotification = (id) => {
  return CommonService.deleteModel(`/cms/notification/delete/${id}`);
};

export const getNotificationDetail = (id) => {
  return CommonService.getModelList(`/cms/notification/detail/${id}`);
};

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../redux/commonReducer";
import Path from "route/Path";
import { Validate } from "commons/tValidate";
import { createFaq } from "service/api/faq";
import FaqForm from "./FaqForm";

export default function FaqAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_021));
    navigate(Path.faq);
  }, [dispatch, navigate]);

  return (
    <FaqForm
      title={"Add FAQ"}
      initialValues={{
        question: "",
        answer: "",
        status: false,
      }}
      readonly={false}
      onSave={createFaq}
      onSuccess={onSuccess}
      isAddScreen={true}
    />
  );
}

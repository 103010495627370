export const Validate = {
  MSG_001: "This is a required field.",
  MSG_002: "Invalid format.",
  MSG_021: "Added successfully.",
  MSG_022: "Updated successfully.",
  MSG_023: "Deleted successfully.",
  MSG_024: "Email already exists.",
  MSG_026: "Email or password is incorrect.",
  MSG_028: "Password must not be identical with one of your most 3 recent used passwords.",
  MSG_029: "Password confirmation does not match.",
  MSG_030: "No Data Available",
  MSG_032: "An error occurred. Please try again.",
  MSG_033: "Invalid File Format",
  MSG_034: "Image Upload Exceeds 5 MB Limit",
  MSG_036: (max) => `This field is higher than maximum value ${max}.`,
  MSG_037: (min) => `This field is lower than minimum value ${min}.`,
  MSG_038: (min) => `This field is shorter than minimum length ${min}.`,
  MSG_039: (max) => `This field is longer than maximum length ${max}.`,
  MSG_040: "Only inactive records are deleted.",
  MSG_041: "Added unsuccessfully",
  MSG_042: "Resolved unsuccessfully",
  MSG_043: "Published successfully",
  MSG_044: "Reactivated successfully",
  MSG_045: "This ATM address has published previously",
  MSG_046: "Not found",
};

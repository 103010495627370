import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbar: {
    key: new Date().getTime(),
    open: false,
    message: "",
    severity: "error",
  },
};

const slice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSuccess: (state, action) => {
      return {
        ...state,
        snackbar: {
          key: new Date().getTime(),
          open: true,
          message: action.payload,
          severity: "success",
        },
      };
    },
    showAlert: (state, action) => {
      return {
        ...state,
        snackbar: {
          key: new Date().getTime(),
          open: true,
          message: action.payload,
          severity: "warning",
        },
      };
    },
    showError: (state, action) => {
      return {
        ...state,
        snackbar: {
          key: new Date().getTime(),
          open: true,
          message: action.payload,
          severity: "error",
        },
      };
    },
    hideSnackbar: (state) => {
      return {
        ...state,
        snackbar: {
          ...state?.snackbar,
          open: false,
        },
      };
    },
  },
});

export const { showSuccess, showAlert, showError, hideSnackbar } = slice.actions;

export default slice.reducer;

const Path = {
  dashboard: "/dashboard",
  tables: "/tables",
  login: "/login",
  profile: "/profile",

  notification: "/notification",
  notificationAdd: "/notification/add",
  notificationDetail: (notificationId) =>
    `/notification/detail/${notificationId || ":notificationId"}`,
  notificationEdit: (notificationId) => `/notification/edit/${notificationId || ":notificationId"}`,

  adminUser: "/admin-user",
  adminUserAdd: "/admin-user/add",
  adminUserDetail: (adminUserId) => `/admin-user/detail/${adminUserId || ":adminUserId"}`,
  adminUserEdit: (adminUserId) => `/admin-user/edit/${adminUserId || ":adminUserId"}`,

  atmMap: "/atm-map",
  atmMapAdd: "/atm-map/add",
  atmMapDetail: (atmMapId) => `/atm-map/detail/${atmMapId || ":atmMapId"}`,
  atmMapEdit: (atmMapId) => `/atm-map/edit/${atmMapId || ":atmMapId"}`,

  faq: "/faq",
  faqAdd: "/faq/add",
  faqDetail: (faqId) => `/faq/detail/${faqId || ":faqId"}`,
  faqEdit: (faqId) => `/faq/edit/${faqId || ":faqId"}`,

  contactSupport: "/contact-support",
  contactSupportDetail: (contactSupportId) =>
    `/contact-support/detail/${contactSupportId || ":contactSupportId"}`,
  contactSupportEdit: (contactSupportId) =>
    `/contact-support/edit/${contactSupportId || ":contactSupportId"}`,

  serviceFee: "/service-fee",

  legalDocument: "/legal-documnent",
  transactions: "/transactions",
  transactionByWalletAdressBuy: (walletAddress) =>
    `/transactions/address/${walletAddress || ":walletAddress"}/buy`,
  transactionByWalletAdressSwap: (walletAddress) =>
    `/transactions/address/${walletAddress || ":walletAddress"}/swap`,
  transactionByWalletAdressSend: (walletAddress) =>
    `/transactions/address/${walletAddress || ":walletAddress"}/send`,
  transactionByWalletAdressReceive: (walletAddress) =>
    `/transactions/address/${walletAddress || ":walletAddress"}/receive`,
  transactionByWalletAdressStake: (walletAddress) =>
    `/transactions/address/${walletAddress || ":walletAddress"}/stake`,
};
export default Path;

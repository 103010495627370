import { useRef } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AuthService from "../../../service/api/AuthService";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { showError } from "../../../redux/commonReducer";

// Images
import { Box, TextField, Typography } from "@mui/material";
import Logo from "../../../assets/images/logo.svg";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthUser } from "../../../redux/authReducer";
import Path from "route/Path";
import { Validate } from "commons/tValidate";

function Basic() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().trim(),
    password: Yup.string().required(),
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    // const fakeData = {
    //   email: "huonght@gmail.com",
    //   pin: "123",
    //   token: "dfasdfsafas",
    // };
    // //test
    // dispatch(setAuthUser(fakeData));
    // console.log(values); //this data send
    await AuthService.login(values)
      .then((res) => {
        const user = res.data;
        // user.is_first_login = false;
        dispatch(setAuthUser(user));
        navigate(Path.dashboard);
        return;
      })
      .catch(() => {
        dispatch(showError(Validate.MSG_026));
        // setErr(t("auth.login.Enter_wrong_infomation"));
      });
  };
  const { handleSubmit, errors, values, setFieldValue, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit,
    validationSchema,
  });

  const handleSetRememberMe = () => setFieldValue("rememberMe", !values.rememberMe);

  return (
    <BasicLayout image="">
      <Card>
        <Box
          container="true"
          textAlign="center"
          justifyContent="center"
          direction="wrap"
          display="flex"
          marginTop={2}
        >
          <Box
            component="img"
            sx={{
              height: 100,
              width: 300,
            }}
            alt="Your logo."
            src={Logo}
          />
        </Box>
        <Typography variant="h2" fontWeight="medium" color="white" mt={2} textAlign="center">
          Sign in
        </Typography>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <TextField
                type="text"
                id="email"
                // ref={userRef}
                value={values.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                autoComplete="off"
                error={Boolean(errors.email)}
                helperText={touched.email && errors.email ? errors.email : ""}
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                type="password"
                id="password"
                onChange={(e) => setFieldValue("password", e.target.value)}
                error={Boolean(errors.password)}
                helperText={touched.password && errors.password ? errors.password : ""}
                value={values.password}
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={values.rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import FaqForm from "./FaqForm";
import { Validate } from "commons/tValidate";
import { getFaqDetail } from "service/api/faq";
import { Box, FormControlLabel, Paper, Switch, TextField, Typography, Link } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import LoadingButton from "@mui/lab/LoadingButton";

export default function FaqDetailPage() {
  const dispatch = useDispatch();
  const { faqId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    faq: {
      question: "",
      answer: "",
      status: false,
    },
  });
  const { faq } = getState;

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !faqId) {
      return cleanup;
    }
    getFaqDetail(faqId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, faq: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError(Validate.MSG_046));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.faq);
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, faqId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  function parseTextWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {part}
          </Link>
        );
      }
      return part;
    });
  }
  return (
    // <FaqForm
    //   title={faq.question}
    //   initialValues={{ ...faq, status: faq.status == 1 ? true : false }}
    //   readonly={true}
    // />
    <DashboardLayout>
      <Box component="form">
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {faq.question}
        </Typography>
        <Box sx={{ xs: 1, sm: 2, md: 3 }} mt={5} mb={5}>
          <Box display="flex" my={3}>
            <Typography>{parseTextWithLinks(faq.answer)}</Typography>
          </Box>

          <Box display="flex" my={3}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="23.70%" fontWeight="bold">
                Status
              </Typography>
              <Box width="70%">
                <FormControlLabel
                  control={<Switch color="primary" readOnly={true} checked={faq.status} />}
                  sx={{ ml: 1 }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <MDButton
            type="button"
            variant="outlined"
            color="info"
            sx={{ minWidth: 150 }}
            onClick={() => navigate(-1)}
          >
            Back
          </MDButton>
          <MDButton
            type="button"
            variant="contained"
            color="info"
            sx={{ ml: 3, minWidth: 150 }}
            onClick={() => navigate(Path.faqEdit(faq.id))}
          >
            Edit
          </MDButton>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

import { Typography } from "@mui/material";

// eslint-disable-next-line react/prop-types
function RequiredAsterisk({ sx }) {
  return (
    <Typography component="span" sx={{ color: "red", ml: 0.5, ...sx }}>
      *
    </Typography>
  );
}

export default RequiredAsterisk;

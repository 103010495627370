import { updateContactSupport } from "../../service/api/contactSupport";

export const resolvedFeedbackAction = ({ id, data, onSuccess, onError }) => {
  updateContactSupport(id, data)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

export const PAGING_LIMITS = [10, 20, 50, 100];

export const LIMIT_DEFAULT = PAGING_LIMITS[0];
export const PAGE = 1;

export const PER_PAGE = 10;

export const PUBLIC_STATUS = 1;

export const NOT_PUBLIC = 0;

export const REGEX_PASSWORD =
  /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\])[0-9A-Za-z!%@#&\-()_[\]{}:;`'",.+*?\\/~$^=<>|\\]{8,64}$/;
export const PHONE_REGEX = /^[+\d]{1,15}$/;

export const LON_LAT_REGEX = /^[0-9.,]{1,15}$/;

export const PRICE_REGEX = /^[0-9]+([,.][0-9]{1,2})?$/;

export const LIST_CURRENTCY = [
  { id: 1, code: "JPY", name: "Đô la Mỹ" },
  { id: 2, code: "USD", name: "Yên Nhật" },
  { id: 3, code: "GBP", name: "Bảng Anh" },
  { id: 4, code: "AUD", name: "Đô la Úc" },
  { id: 5, code: "CAD", name: "Đô la Canada" },
  { id: 6, code: "CNY", name: "Nhân dân tệ Trung Quốc" },
  { id: 7, code: "INR", name: "Rupee Ấn Độ" },
  { id: 8, code: "BRL", name: "Real Brazil" },
  { id: 9, code: "CHF", name: "Franc Thụy Sĩ" },
  { id: 10, code: "SEK", name: "Krona Thụy Điển" },
  { id: 11, code: "NZD", name: "Đô la New Zealand" },
  { id: 12, code: "ZAR", name: "Rand Nam Phi" },
  { id: 13, code: "MXN", name: "Peso Mexico" },
  { id: 14, code: "RUB", name: "Rúp Nga" },
];

export const OPEN_STATUS = 1;

export const RESOLVED_STATUS = 2;

export const TRANSACTION_TYPE_BUY = 1;
export const TRANSACTION_TYPE_SWAP = 2;
export const TRANSACTION_TYPE_SEND = 3;
export const TRANSACTION_TYPE_RECEIVE = 4;
export const TRANSACTION_TYPE_STAKE = 5;

export const NETWORK_BTC = 1;
export const NETWORK_ETH = 2;
export const NETWORK_BSC = 3;
export const NETWORK_TRX = 4;
export const NETWORK_XRP = 5;
export const NETWORK_ADA = 6;
export const NETWORK_CENNZ = 7;

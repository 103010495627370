import CommonService from "./CommonService";
import client from "./client";

export const list = (params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
    search: String(params.keyword),
    status: String(params.status),
  };

  if (params.sort && params.sortField) {
    searchParam.sort_by = params.sort;
    searchParam.order_by = params.sortField;
  }
  return CommonService.getModelList("/cms/wallets", new URLSearchParams(searchParam));
};

export const listTransactionByType = (wallet_address, type, params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
    time: String(params.time),
    hash_id: String(params.hash_id),
    type: type,
  };
  const url = `/cms/wallets/transactions-by-type/${wallet_address}`;
  return CommonService.getModelList(url, new URLSearchParams(searchParam));
};

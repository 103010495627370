import { PAGING_LIMITS } from "commons/const";
import { LIMIT_DEFAULT } from "commons/const";

export const getPageLimit = (input) => {
  const number = Number(input);
  if (Number.isNaN(number) || !PAGING_LIMITS.includes(number)) {
    return LIMIT_DEFAULT;
  }
  return number;
};

export const getSortDirection = (input) => {
  if (input === "asc" || input === "desc") {
    return input;
  }
  return "desc";
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    updateAuthUser: (state, action) => {
      if (state.user) {
        Object.assign(state.user, action.payload);
      }
    },
    clearAuth: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { setAuthUser, clearAuth, updateAuthUser } = slice.actions;

export default slice.reducer;

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import ContactSupportForm from "layouts/contactSupport/ContactSupportForm";
import { getContactSupportDetail } from "service/api/contactSupport";
import { OPEN_STATUS } from "commons/const";

export default function ContactSupportDetailPage() {
  const dispatch = useDispatch();
  const { contactSupportId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    contactSupport: {
      name: "",
      email: "",
      inquiry: "",
      feedback: "",
      status: OPEN_STATUS,
    },
  });
  const { contactSupport } = getState;

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !contactSupportId) {
      return cleanup;
    }
    getContactSupportDetail(contactSupportId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, contactSupport: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError("NoExist"));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.contactSupport, { state: location });
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, contactSupportId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return <ContactSupportForm title="View Inquiry" initialValues={contactSupport} readonly={true} />;
}

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../../src/redux/commonReducer";
import { createNotification } from "service/api/notification";
import NotificationForm from "./NotificationForm";
import { Validate } from "commons/tValidate";
import Path from "route/Path";
import { PUBLIC_STATUS } from "commons/const";

function NotificationAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_021));
    navigate(Path.notification);
  }, [dispatch, navigate]);

  return (
    <NotificationForm
      title={"Add Notification"}
      initialValues={{
        title: "",
        message: "",
        status: false,
      }}
      onSave={createNotification}
      onSuccess={onSuccess}
      isAddScreen={true}
    />
  );
}

export default NotificationAddPage;

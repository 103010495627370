import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { hideSnackbar } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function AppSnackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.common.snackbar);

  const { key, open, message, severity, ...rest } = snackbar;

  const onClose = () => dispatch(hideSnackbar());

  return (
    <Snackbar
      key={key}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      {...(rest || {})}
    >
      <Alert
        severity={severity}
        variant="standard"
        action={
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AppSnackbar;

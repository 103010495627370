import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { PER_PAGE } from "commons/const";
// Data
import {
  Box,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { TEXT_LINE_CLAMP } from "theme/themeConst";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { getSortDirection } from "utils/search";
import { getPageLimit } from "utils/search";
import { listAtmMap } from "service/api/atmMap";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import NoData from "components/NoData";
import HeaderSorted from "components/HeaderSorted";
import CustomPagination from "components/CustomPagination";
// import SimpleBadge from "components/SimpleBadge";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../redux/commonReducer";
import Path from "route/Path";
import { updateServiceFee } from "service/api/serviceFee";
import { listServiceFee } from "service/api/serviceFee";
import ServiceFeeEditDialog from "./ServiceFeeEditDialog";
import { Validate } from "commons/tValidate";
import * as Yup from "yup";
import { PRICE_REGEX } from "commons/const";

export default function ServiceFee() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams({
    sort_field: "id",
    sort: "desc",
    page: "1",
    per_page: PER_PAGE,
  });

  const [searchState, setSearchState] = useState({
    error: "",
    rows: [],
    total: 0,
    totalPages: 0,
  });

  const [updateState, setUpdateState] = useState({
    loading: false,
    open: false,
  });

  const validationSchema = Yup.object().shape({
    detailServiceFee: Yup.object().shape({
      service_fee_type: Yup.string().required(Validate.MSG_001),
      service_value: Yup.string()
        .trim()
        .required(Validate.MSG_001)
        .matches(PRICE_REGEX, Validate.MSG_002),
    }),
  });

  const createNewSearchParams = (values) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("sort_field", values.sortField || "id");
    newSearchParams.set("sort", values.sort || "");
    newSearchParams.set("per_page", `${values.perPage}`);
    return newSearchParams;
  };

  const onSubmit = (values, helpers) => {
    const newSearchParams = createNewSearchParams(values);
    newSearchParams.set("page", "1");
    if (isSameSearchParams(searchParams, newSearchParams)) {
      if (values.page !== 1) {
        helpers.setFieldValue("page", 1);
      }
      helpers.setFieldValue("loading", true);
    } else {
      setSearchParams(newSearchParams);
    }
  };

  const { handleSubmit, values, setFieldValue, setSubmitting, isSubmitting, errors, touched } =
    useFormik({
      initialValues: {
        loading: true,
        detailServiceFee: {
          id: "",
          transaction_type: "",
          service_fee_type: 0,
          service_value: "",
          currency_code: "",
        },
      },
      onSubmit,
      validationSchema,
      enableReinitialize: true,
    });

  // Update
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!isSubmitting) {
      return cleanup;
    }
    setUpdateState({ ...updateState, loading: true });
    const data = {
      ...values.detailServiceFee,
      currency_code:
        values.detailServiceFee.service_fee_type == "Percentage"
          ? "%"
          : values.detailServiceFee.currency_code,
    };
    updateServiceFee(values.detailServiceFee?.id, data)
      .then(() => {
        // if (!mounted) return;
        setSubmitting(false);
        setUpdateState({ ...updateState, open: false, loading: false });
        dispatch(showSuccess(Validate.MSG_022));
        setFieldValue("loading", true);
      })
      .catch((e) => {
        setUpdateState({ ...updateState, open: false, loading: false });
        setSubmitting(false);
        if (!mounted) return;
        if (e.response?.msg === "scheduleGreaterNow") {
          dispatch(showError("scheduleGreaterNow"));
        }
      });
    return cleanup;
  }, [dispatch, setFieldValue, isSubmitting]);

  // List
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!values.loading) {
      return cleanup;
    }

    listServiceFee(values)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setSearchState({
          error: "",
          total: data.total,
          totalPages: data.totalPages,
          rows: data.row,
        });
        setFieldValue("loading", false);
      })
      .catch((e) => {
        if (!mounted) return;
        setSearchState({
          error: e.message,
          rows: [],
          total: 0,
          totalPages: 0,
        });
        setFieldValue("loading", false);
      });
    return cleanup;
  }, [setFieldValue, values]);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={6} md={11}>
          <Typography fontWeight="bold">Service Fee Dashboard</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 5 }}>
        {/* <Card> */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: 50 }}>
                  No
                </TableCell>
                <TableCell align="center" sx={{ width: 400 }}>
                  Transaction type
                </TableCell>
                <TableCell align="center" sx={{ width: 400 }}>
                  Service fee type
                </TableCell>
                <TableCell align="center" sx={{ width: 400 }}>
                  Service value
                </TableCell>
                <TableCell align="center" sx={{ width: 300 }}>
                  action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                if (values.loading) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }

                if (!searchState.rows.length) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <NoData />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
                return searchState.rows.map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ ...TEXT_LINE_CLAMP(), cursor: "pointer" }}>
                        {item.transaction_type}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ ...TEXT_LINE_CLAMP() }}>{item.service_fee_type}</Box>
                    </TableCell>
                    <TableCell align="center">
                      {item.service_value}{" "}
                      {Number(item.service_value) > 0 ? item.currency_code : ""}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        sx={{ mx: 0.5 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setUpdateState((v) => ({
                            ...v,
                            open: true,
                            loading: false,
                          }));
                          setFieldValue("detailServiceFee", item);
                        }}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ));
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ServiceFeeEditDialog
        values={values.detailServiceFee}
        open={updateState.open}
        setFieldValueItem={(item) => setFieldValue("detailServiceFee", item)}
        onClose={() => {
          setUpdateState((v) => ({
            ...v,
            open: false,
          }));
          setFieldValue("detailServiceFee", {});
        }}
        handleSubmit={handleSubmit}
        errors={errors}
        touched={touched}
        loading={updateState.loading}
      />
    </DashboardLayout>
  );
}

/* eslint-disable react/prop-types */
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography, InputLabel } from "@mui/material";
import { showError } from "../../redux/commonReducer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import { Validate } from "commons/tValidate";
import JoditEditor from "jodit-react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

function LegalDocumentForm({ readonly = false, title, initialValues, onSave, onSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useState({
    loading: false,
    values: undefined,
  });
  const [privacyPolicyError, setPrivacyPolicyError] = useState();
  const [termAndConditionError, setTermAndConditionError] = useState();

  //custom yup to check empty richtext (remove html tag)
  Yup.addMethod(Yup.string, "checkEmptyRichText", function (errorMessage) {
    return this.test(`checkEmptyRichText`, errorMessage, function (value) {
      // for case update
      const text = value.replace(/<[^>]*>/g, "");
      if (text.length > 0) {
        return true;
      } else {
        const { path, createError } = this;
        return createError({ path, message: errorMessage });
      }
    });
  });

  const validationParam = {
    privacy_policy: Yup.string()
      .trim()
      .required("privacy_policy")
      .min(3, Validate.MSG_038(3))
      .max(9999999, Validate.MSG_039(9999999))
      .checkEmptyRichText("privacy_policy"),
    term_and_condition: Yup.string()
      .trim()
      .required("term_and_condition")
      .min(3, Validate.MSG_038(3))
      .max(9999999, Validate.MSG_039(9999999))
      .checkEmptyRichText("term_and_condition"),
  };

  const validationSchema = Yup.object().shape(validationParam);

  const onSubmit = async (values) => {
    setSubmitState((v) => ({ ...v, loading: true, values }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validate: (values) => {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (error) {
        console.log(error);
        if (error.errors[0] === "privacy_policy") {
          setPrivacyPolicyError(Validate.MSG_001);
        }

        if (error.errors[0] === "term_and_condition" || error.errors[1] === "term_and_condition") {
          setTermAndConditionError(Validate.MSG_001);
        }
      }
    },
  });
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldError, setSubmitting } =
    formik;

  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!submitState.loading || !submitState.values) {
      return cleanup;
    }
    onSave?.(submitState.values)
      .then((res) => {
        if (res) {
          setSubmitState((v) => ({ ...v, loading: false }));
          onSuccess();
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(showError(Validate.MSG_032));
        setSubmitState((v) => ({ ...v, loading: false }));
      });
    return cleanup;
  }, [
    dispatch,
    onSave,
    onSuccess,
    setFieldError,
    setSubmitting,
    submitState.loading,
    submitState.values,
    values.contents,
  ]);
  const btnJodit = [
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "paragraph",
    "link",
    "table",
    "|",
    "cut",
    "copy",
    "paste",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "brush",
    "fullsize",
    "selectall",
    "preview",
    "source",
  ];
  const editorConfig = {
    buttons: btnJodit,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const btnSunNote = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["removeFormat"],
    ["fontColor", "hiliteColor"],
    ["outdent", "indent"],
    ["align", "horizontalRule", "list", "table"],
    ["link"],
    ["fullScreen", "showBlocks", "codeView"],
    ["preview", "print"],
  ];
  return (
    <DashboardLayout>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <Box mt={5} mb={5} p={3}>
          <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
            Privacy Policy:
          </Typography>
          <Box m={2} width="100%">
            {/* <JoditEditor
              value={values.privacy_policy}
              onBlur={(newContent1) => setFieldValue("privacy_policy", newContent1)}
              error={Boolean(errors?.privacy_policy && touched?.privacy_policy)}
              onChange={() => {
                setPrivacyPolicyError("");
              }}
              config={editorConfig}
            /> */}
            <SunEditor
              lang="en"
              setContents={values.privacy_policy}
              height="500px"
              setOptions={{ buttonList: btnSunNote }}
              onChange={(newContent1) => {
                setFieldValue("privacy_policy", newContent1);
                setPrivacyPolicyError("");
              }}
            />
            <InputLabel sx={{ color: "red", marginTop: "10px" }}>{privacyPolicyError}</InputLabel>
          </Box>
        </Box>
        <Box mt={5} mb={5} p={3}>
          <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
            Term and Conditions:
          </Typography>
          <Box m={2} width="100%">
            {/* <JoditEditor
              value={values.term_and_condition}
              onBlur={(newContent2) => setFieldValue("term_and_condition", newContent2)}
              error={Boolean(errors?.term_and_condition && touched?.term_and_condition)}
              onChange={() => {
                setTermAndConditionError("");
              }}
              config={editorConfig}
            /> */}
            <SunEditor
              lang="en"
              setContents={values.term_and_condition}
              height="500px"
              setOptions={{ buttonList: btnSunNote }}
              onChange={(newContent2) => {
                setFieldValue("term_and_condition", newContent2);
                setTermAndConditionError("");
              }}
            />
            <InputLabel sx={{ color: "red", marginTop: "10px" }}>
              {termAndConditionError}
            </InputLabel>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <MDButton type="submit" variant="contained" color="info" sx={{ ml: 3, minWidth: 150 }}>
            Save
          </MDButton>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default LegalDocumentForm;

/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Typography, Select, MenuItem } from "@mui/material";
import { showError } from "../../../src/redux/commonReducer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import RequiredAsterisk from "components/RequiredAsterisk";
import { Validate } from "commons/tValidate";
import { REGEX_PASSWORD } from "commons/const";
import { checkEmailExist } from "service/api/adminUser";

function AdminUserForm({
  readonly = false,
  title,
  initialValues,
  onSave,
  onSuccess,
  isAddScreen = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useState({
    loading: false,
    values: undefined,
  });

  //custom yup to check exist email in db
  Yup.addMethod(Yup.string, "checkEmailExist", function (errorMessage) {
    return this.test(`checkEmailExist`, errorMessage, async function (value) {
      // for case update
      if (initialValues.email === value) return true;

      // for case create
      const { path, createError } = this;
      const res = await checkEmailExist(value);
      return !res.data.data || createError({ path, message: errorMessage });
    });
  });
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const validationParam = {
    name: Yup.string()
      .trim()
      .required(Validate.MSG_001)
      .min(3, Validate.MSG_038(3))
      .max(30, Validate.MSG_039(30)),
    email: Yup.string()
      .required(Validate.MSG_001)
      .email(Validate.MSG_002)
      .max(255, Validate.MSG_039(255))
      .matches(emailRegex, Validate.MSG_002)
      .checkEmailExist(Validate.MSG_024),
    role: Yup.string(),
  };
  if (isAddScreen) {
    validationParam.password = Yup.string()
      .required(Validate.MSG_001)
      .matches(REGEX_PASSWORD, Validate.MSG_002);
  }

  const validationSchema = Yup.object().shape(validationParam);

  const onSubmit = (values) => {
    setSubmitState((v) => ({ ...v, loading: true, values }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldError, setSubmitting } =
    formik;

  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!submitState.loading || !submitState.values) {
      return cleanup;
    }
    onSave?.(submitState.values)
      .then((res) => {
        if (res) {
          setSubmitState((v) => ({ ...v, loading: false }));
          onSuccess();
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(showError(Validate.MSG_032));
        setSubmitState((v) => ({ ...v, loading: false }));
      });
    return cleanup;
  }, [
    dispatch,
    onSave,
    onSuccess,
    setFieldError,
    setSubmitting,
    submitState.loading,
    submitState.values,
    values.contents,
  ]);
  return (
    <DashboardLayout>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <Box mt={5} mb={5}>
          <Box display="flex" my={3}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="25%">
                Name
                <RequiredAsterisk />
              </Typography>
              <Box width="70%">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 255, readOnly: readonly }}
                  placeholder="Enter name"
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                  error={Boolean(errors?.name && touched?.name)}
                  helperText={errors?.name && touched?.name ? errors?.name : ""}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" my={3}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="25%">
                Email
                <RequiredAsterisk />
              </Typography>
              <Box width="70%">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 255, readOnly: readonly }}
                  placeholder="Enter email"
                  value={values.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  error={Boolean(errors?.email && touched?.email)}
                  helperText={errors?.email && touched?.email ? errors?.email : ""}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" my={3} sx={{ display: isAddScreen ? "unset" : "none" }}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="25%">
                Password
                <RequiredAsterisk />
              </Typography>
              <Box width="70%">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  placeholder="Enter password"
                  value={values.nextAction}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  error={Boolean(errors?.password && touched?.password)}
                  helperText={errors?.password && touched?.password ? errors?.password : ""}
                />
              </Box>
            </Box>
          </Box>
          {!readonly && !isAddScreen ? (
            <Box display="flex" my={3}>
              <Box width="50%" sx={{ display: "flex" }}>
                <Typography width="25%">
                  Status
                  <RequiredAsterisk />
                </Typography>
                <Box width="70%">
                  <Select
                    fullWidth
                    inputProps={{ readOnly: readonly }}
                    sx={{ py: "0.75rem" }}
                    value={values.status}
                    onChange={(e) => setFieldValue("status", e.target.value)}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}
          <Box display="flex" my={3}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="25%">
                Role
                <RequiredAsterisk />
              </Typography>
              <Box width="70%">
                <Select
                  fullWidth
                  inputProps={{ readOnly: readonly }}
                  sx={{ py: "0.75rem" }}
                  value={"admin"}
                  onChange={(e) => setFieldValue("role", e.target.value)}
                >
                  <MenuItem value={"admin"}>Admin</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <MDButton
            type="button"
            variant="outlined"
            color="info"
            sx={{ minWidth: 150 }}
            onClick={() => navigate(-1)}
          >
            Back
          </MDButton>
          {!readonly ? (
            submitState.loading ? (
              <LoadingButton
                loading={submitState.loading}
                type="button"
                variant="contained"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </LoadingButton>
            ) : (
              <MDButton
                type="submit"
                variant="contained"
                color="info"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </MDButton>
            )
          ) : (
            ""
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default AdminUserForm;

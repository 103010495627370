import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const slice = createSlice({
  name: "previousRoute",
  initialState,
  reducers: {
    setRoute: (state, action) => {
      return action.payload;
    },

    setRouteEmpty: () => {
      return null;
    },
  },
});

export const { setRoute, setRouteEmpty } = slice.actions;

export default slice.reducer;

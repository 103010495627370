import CommonService from "./CommonService";
import client from "./client";

export const listAdminUser = (params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
    keyword: String(params.keyword).trim(),
    status: String(params.status).trim(),
    order_by: String(params.sortField),
    sort_by: String(params.sort),
  };
  return CommonService.getModelList("/admin", new URLSearchParams(searchParam));
};

export const createAdminUser = (data) => {
  data.name = data.name.trim();
  data.email = data.email.trim();
  return CommonService.postModel(`/admin/create`, data);
};

export const updateAdminUser = (id, data) => {
  return CommonService.updateModel(`/admin/update/${id}`, data);
};

export const deleteAdminUser = (id) => {
  return CommonService.deleteModel(`/admin/delete/${id}`);
};

export const getAdminUserDetail = (id) => {
  return CommonService.getModelList(`/admin/detail/${id}`);
};

export const checkEmailExist = (email) => {
  return CommonService.getModelList("/admin/check-email", new URLSearchParams({ email: email }));
};

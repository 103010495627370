import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import NotificationForm from "layouts/notification/NotificationForm";
import { PUBLIC_STATUS } from "commons/const";
import { getNotificationDetail } from "service/api/notification";

export default function NotificationDetailPage() {
  const dispatch = useDispatch();
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    notification: {
      title: "",
      message: "",
      status: PUBLIC_STATUS,
    },
  });
  const { notification } = getState;

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !notificationId) {
      return cleanup;
    }
    getNotificationDetail(notificationId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, notification: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError("NoExist"));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.notification, { state: location });
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, notificationId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return (
    <NotificationForm
      title="Notification Information"
      initialValues={notification}
      readonly={true}
      isAddScreen={false}
    />
  );
}

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../../src/redux/commonReducer";
import { createAdminUser } from "service/api/adminUser";
import AdminUserForm from "./AdminUserForm";
import { Validate } from "commons/tValidate";
import Path from "route/Path";

function AdminUserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_021));
    navigate(Path.adminUser);
  }, [dispatch, navigate]);

  return (
    <AdminUserForm
      title={"Add User"}
      initialValues={{
        name: "",
        email: "",
        password: "",
        role: "",
      }}
      onSave={createAdminUser}
      onSuccess={onSuccess}
      isAddScreen={true}
    />
  );
}

export default AdminUserAddPage;

import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { PER_PAGE } from "commons/const";
// Data
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Link,
  Tooltip,
} from "@mui/material";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getPageLimit } from "utils/search";
import NoData from "components/NoData";
import CustomPagination from "components/CustomPagination";
import { useDispatch } from "react-redux";
import { listTransactionByType } from "service/api/transaction";
import SearchIcon from "@mui/icons-material/Search";
import Path from "route/Path";
import SearchControl from "components/Form/SearchControl";
import moment from "moment";
import {
  TRANSACTION_TYPE_BUY,
  TRANSACTION_TYPE_SWAP,
  TRANSACTION_TYPE_SEND,
  TRANSACTION_TYPE_RECEIVE,
  TRANSACTION_TYPE_STAKE,
  NETWORK_BTC,
  NETWORK_ETH,
  NETWORK_BSC,
  NETWORK_TRX,
  NETWORK_XRP,
  NETWORK_ADA,
  NETWORK_CENNZ,
} from "commons/const";
import { TEXT_LINE_CLAMP } from "theme/themeConst";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { styled } from "@mui/system";

export default function TransactionsHistoryPageBuy() {
  // const StyledSelect = styled(Select)(({ theme }) => ({
  //   ".MuiSelect-iconOutlined": { display: "unset" },
  // }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { walletAddress } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({
    time: "",
    hash_id: "",
    page: "1",
    per_page: PER_PAGE,
  });

  const [searchState, setSearchState] = useState({
    error: "",
    rows: [],
    total: 0,
    totalPages: 0,
  });

  const createNewSearchParams = (values) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("time", values.time || "");
    newSearchParams.set("hash_id", `${values.hash_id}` || "");
    newSearchParams.set("per_page", `${values.perPage}`);
    return newSearchParams;
  };

  const onSubmit = (values, helpers) => {
    const newSearchParams = createNewSearchParams(values);
    newSearchParams.set("page", "1");
    if (isSameSearchParams(searchParams, newSearchParams)) {
      if (values.page !== 1) {
        helpers.setFieldValue("page", 1);
      }
      helpers.setFieldValue("loading", true);
    } else {
      setSearchParams(newSearchParams);
    }
  };

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      time: searchParams.get("time") || "",
      hash_id: searchParams.get("hash_id") || "",
      loading: true,
      page: Number(searchParams.get("page")) || 1,
      perPage: getPageLimit(searchParams.get("per_page")),
    },
    onSubmit,
    enableReinitialize: true,
  });

  // List
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!values.loading) {
      return cleanup;
    }
    listTransactionByType(walletAddress, 1, values)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setSearchState({
          error: "",
          total: data.total,
          totalPages: data.totalPages,
          rows: data.row,
        });
        setFieldValue("loading", false);
      })
      .catch((e) => {
        if (!mounted) return;
        setSearchState({
          error: e.message,
          rows: [],
          total: 0,
          totalPages: 0,
        });
        setFieldValue("loading", false);
      });
    return cleanup;
  }, [setFieldValue, values]);

  const handleChangeType = (type) => {
    switch (type) {
      case TRANSACTION_TYPE_BUY:
        navigate(Path.transactionByWalletAdressBuy(walletAddress), { replace: true });
        break;
      case TRANSACTION_TYPE_SWAP:
        navigate(Path.transactionByWalletAdressSwap(walletAddress), { replace: true });
        break;
      case TRANSACTION_TYPE_SEND:
        navigate(Path.transactionByWalletAdressSend(walletAddress), { replace: true });
        break;
      case TRANSACTION_TYPE_RECEIVE:
        navigate(Path.transactionByWalletAdressReceive(walletAddress), { replace: true });
        break;
      case TRANSACTION_TYPE_STAKE:
        navigate(Path.transactionByWalletAdressStake(walletAddress), { replace: true });
        break;
    }
  };

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={6} md={11}>
          <Typography fontWeight="bold">Transaction History Details</Typography>
        </Grid>
      </Grid>

      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "flex-end",
          // justifyContent: "space-between",
          mx: -1,
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <FormControl sx={{ width: "20%" }}>
          <SearchControl
            sx={{ width: "100%", flexShrink: 0, px: 1, mr: 1 }}
            mr={1}
            label="Transaction Type"
            control={
              // <StyledSelect
              <Select
                value={TRANSACTION_TYPE_BUY}
                onChange={(e) => handleChangeType(e.target.value)}
                sx={{ py: "0.75rem" }}
              >
                <MenuItem value={TRANSACTION_TYPE_BUY}>Buy</MenuItem>
                <MenuItem value={TRANSACTION_TYPE_SWAP}>Swap</MenuItem>
                <MenuItem value={TRANSACTION_TYPE_SEND}>Send</MenuItem>
                <MenuItem value={TRANSACTION_TYPE_RECEIVE}>Receive</MenuItem>
                <MenuItem value={TRANSACTION_TYPE_STAKE}>Stake</MenuItem>
                {/* </StyledSelect> */}
              </Select>
            }
          />
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              selected={values.time}
              onChange={(e) => {
                setFieldValue("time", e);
                let date = moment(e).format("YYYY-MM-DD");
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("time", `${date}`);
                setSearchParams(newSearchParams);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <SearchControl
          sx={{ width: "30%", flexShrink: 0, px: 1 }}
          label=""
          control={
            <TextField
              fullWidth
              value={values.hash_id}
              placeholder="Enter HashId"
              onChange={(e) => {
                setFieldValue("hash_id", e.target.value);
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("hash_id", `${e.target.value}`);
                setSearchParams(newSearchParams);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      </Box>
      <Box sx={{ marginTop: 5 }}>
        {/* <Card> */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: 200 }}>
                  Time
                </TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  Type
                </TableCell>
                <TableCell align="center" sx={{ width: 350 }}>
                  Value
                </TableCell>
                <TableCell align="center" sx={{ width: 100 }}>
                  Provider
                </TableCell>
                <TableCell align="center" sx={{ width: 300 }}>
                  Destination
                </TableCell>
                <TableCell align="center" sx={{ width: 300 }}>
                  HashId
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                if (values.loading) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }

                if (!searchState.rows.length) {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} sx={{ py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <NoData />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
                return searchState.rows.map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell align="center">
                      {moment(item.created_at).format("MMM DD YYYY")}
                    </TableCell>
                    <TableCell align="center">Buy</TableCell>
                    <TableCell align="center">{item.value + " " + item.token_code}</TableCell>
                    <TableCell align="center">{item.provider}</TableCell>
                    <TableCell align="center">
                      <Tooltip title={item.destination}>
                        <Box
                          sx={{
                            ...TEXT_LINE_CLAMP(),
                          }}
                        >
                          {item.destination}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href="#"
                        onClick={() => {
                          window.open(process.env.REACT_APP_SIMPLEX_URL, "_blank");
                        }}
                      >
                        <Tooltip title={item.hash_id}>
                          <Box
                            sx={{
                              ...TEXT_LINE_CLAMP(),
                              "&:hover": {
                                color: "blue",
                                textDecorationLine: "underline",
                              },
                            }}
                          >
                            {item.hash_id}
                          </Box>
                        </Tooltip>
                      </a>
                    </TableCell>
                  </TableRow>
                ));
              })()}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          loading={values.loading}
          page={values.page}
          totalPage={Math.ceil(searchState.total / values.perPage)}
          handleChangePage={(changeValue) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set("page", `${values.page + changeValue}`);
            setSearchParams(newSearchParams);
          }}
        />
      </Box>
    </DashboardLayout>
  );
}

import { useEffect } from "react";
import { Grid, Pagination } from "@mui/material";
import { FLEX_CENTER_CENTER } from "theme/themeConst";
import { styled } from "@mui/material/styles";

const primary600 = "#0284C7";

const GPagination = styled(Pagination)(() => ({
  ".Mui-selected": {
    backgroundColor: `${primary600} !important`,
    color: "white",
  },
}));

// eslint-disable-next-line react/prop-types
function CustomPagination({ page, totalPage, handleChangePage, loading }) {
  useEffect(() => {
    if (page < 1) {
      handleChangePage(1 - page);
    }

    if (totalPage && page > totalPage) {
      handleChangePage(totalPage - page);
    }
  }, [page, totalPage]);

  return totalPage === 1 ? (
    <span />
  ) : (
    <Grid container justifyContent="flex-end" mt={5}>
      <Pagination
        disabled={loading}
        count={totalPage ? Number(totalPage) : 0}
        page={page}
        onChange={(_, newPage) => {
          if (newPage !== page) {
            handleChangePage(newPage - page);
          }
        }}
        // shape="rounded"
        hidePrevButton
        hideNextButton
      />
    </Grid>
  );
}

export default CustomPagination;

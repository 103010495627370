/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Typography, Select, MenuItem, InputLabel } from "@mui/material";
import { showError, showSuccess } from "../../../src/redux/commonReducer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import RequiredAsterisk from "components/RequiredAsterisk";
import { Validate } from "commons/tValidate";
import moment from "moment";
import JoditEditor from "jodit-react";
import Path from "route/Path";
import { updateContactSupport } from "service/api/contactSupport";
import { color } from "@mui/system";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { resolvedFeedbackAction } from "./helper";

function ContactSupportForm({ readonly = false, title, initialValues, onSave, onSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [submitState, setSubmitState] = useState({
    loading: false,
    values: undefined,
  });
  const [isReadOnly, setIsReadOnly] = useState(true);
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [location.pathname]);
  const [feedbackError, setFeedbackError] = useState();
  //custom yup to check empty richtext (remove html tag)
  Yup.addMethod(Yup.string, "checkEmptyRichText", function (errorMessage) {
    return this.test(`checkEmptyRichText`, errorMessage, function (value) {
      // for case update
      const text = value.replace(/<[^>]*>/g, "");
      if (text.length > 0) {
        return true;
      } else {
        const { path, createError } = this;
        return createError({ path, message: errorMessage });
      }
    });
  });

  Yup.addMethod(Yup.string, "maxlengthRichText", function (errorMessage) {
    return this.test(`maxlengthRichText`, errorMessage, function (value) {
      // for case update
      const text = value.replace(/<[^>]*>/g, "");
      if (text.length <= 2000) {
        return true;
      } else {
        const { path, createError } = this;
        return createError({ path, message: errorMessage });
      }
    });
  });

  const validationParam = {
    feedback: Yup.string()
      .trim()
      .required(Validate.MSG_001)
      .min(3, Validate.MSG_038(3))
      // .max(2000, Validate.MSG_039(2000)) //custom check max length, remove all html tag
      .checkEmptyRichText(Validate.MSG_001)
      .maxlengthRichText(Validate.MSG_039(2000)),
  };

  const validationSchema = Yup.object().shape(validationParam);

  const onSubmit = async (values) => {
    setSubmitState((v) => ({ ...v, loading: true, values }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validate: (values) => {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (error) {
        setFeedbackError(error.errors);
      }
    },
  });
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldError, setSubmitting } =
    formik;

  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!submitState.loading || !submitState.values) {
      return cleanup;
    }
    onSave?.(submitState.values)
      .then((res) => {
        if (res) {
          setSubmitState((v) => ({ ...v, loading: false }));
          onSuccess();
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(showError(Validate.MSG_032));
        setSubmitState((v) => ({ ...v, loading: false }));
      });
    return cleanup;
  }, [
    dispatch,
    onSave,
    onSuccess,
    setFieldError,
    setSubmitting,
    submitState.loading,
    submitState.values,
    values.contents,
  ]);

  const handleResolved = async () => {
    try {
      await validationSchema.validateSync(values, { abortEarly: false });
      values.status = 2;
      setSubmitState((v) => ({
        ...v,
        loading: true,
        values,
      }));
    } catch (error) {
      setFeedbackError(error.errors);
    }
  };
  const btnJodit = [
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "paragraph",
    "link",
    "table",
    "|",
    "cut",
    "copy",
    "paste",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "brush",
    "fullsize",
    "selectall",
    "preview",
    "source",
  ];
  const editorConfig = {
    buttons: btnJodit,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const btnSunNote = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["removeFormat"],
    ["fontColor", "hiliteColor"],
    ["outdent", "indent"],
    ["align", "horizontalRule", "list", "table"],
    ["link"],
    ["fullScreen", "showBlocks", "codeView"],
    ["preview", "print"],
  ];
  const onResolvedFeedback = async (id, feedback) => {
    resolvedFeedbackAction({
      id,
      data: {
        status: 2,
        feedback,
      },
      onSuccess: () => {
        dispatch(showSuccess(Validate.MSG_022));
        setFieldValue("loading", true);
        navigate("/contact-support");
      },
      onError: (error) => {
        dispatch(showError(error?.response?.msg));
      },
    });
  };
  return (
    <DashboardLayout>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <Box mt={5} mb={5} p={3} sx={{ border: "1px solid grey" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
            User Information:
          </Typography>
          <Box m={2} sx={{ display: "flex" }}>
            <Box width="50%" fontSize={16}>
              Name: {values.name}
            </Box>
            <Box width="50%" fontSize={16} textAlign="end">
              Email: {values.email}
            </Box>
          </Box>
        </Box>
        <Box mt={5} mb={5} p={3} sx={{ border: "1px solid grey" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
            Inquiry Detail:
          </Typography>
          <Box m={2} sx={{ display: "flex" }}>
            <Box width="50%" fontSize={16}></Box>
            <Box width="50%" fontSize={16} textAlign="end">
              Created Date: {moment(values.created_at).format("MMM DD YYYY")}
            </Box>
          </Box>
          <Box m={2} sx={{ display: "flex" }}>
            <Box width="50%" fontSize={16}></Box>
            <Box width="50%" fontSize={16} textAlign="end">
              Updated Date: {moment(values.updated_at).format("MMM DD YYYY")}
            </Box>
          </Box>
          <Box m={2} fontSize={16} sx={{ display: "flex", wordBreak: "break-word" }}>
            {values.inquiry}
          </Box>
        </Box>
        <Box mt={5} mb={5} p={3} sx={{ border: "1px solid grey" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
            Feedback from FG Wallet:
          </Typography>
          {!readonly ? (
            <Box m={2} width="100%">
              {/* <JoditEditor
                value={values.feedback}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setFieldValue("feedback", newContent)}
                error={Boolean(errors?.feedback && touched?.feedback)}
                onChange={() => {
                  setFeedbackError("");
                }}
                config={editorConfig}
              />
               */}
              <SunEditor
                setContents={values.feedback}
                height="300px"
                setOptions={{ buttonList: btnSunNote }}
                onChange={(newContent) => {
                  setFieldValue("feedback", newContent);
                  setFeedbackError("");
                }}
              />
              <InputLabel sx={{ color: "red", marginTop: "10px" }}>{feedbackError}</InputLabel>
            </Box>
          ) : (
            <SunEditor
              setContents={values.feedback}
              height="300px"
              readOnly
              disable
              setOptions={{ buttonList: btnSunNote }}
              onChange={(newContent) => {
                setFieldValue("feedback", newContent);
                setFeedbackError("");
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          {readonly ? (
            <>
              <MDButton
                type="button"
                variant="contained"
                color="info"
                sx={{ minWidth: 150 }}
                onClick={() => navigate(-1)}
              >
                Back
              </MDButton>
              {values.status !== 2 ? (
                <MDButton
                  type="button"
                  variant="contained"
                  color="info"
                  sx={{ ml: 3, minWidth: 150 }}
                  onClick={() => navigate(Path.contactSupportEdit(values.id))}
                >
                  Edit Feedback
                </MDButton>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <MDButton
                type="button"
                variant="contained"
                color="info"
                sx={{ minWidth: 150 }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </MDButton>
              <MDButton
                type="submit"
                variant="contained"
                color="info"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                Save
              </MDButton>
            </>
          )}
          {values.status == 1 && values.feedback && isReadOnly ? (
            <MDButton
              variant="contained"
              color="info"
              sx={{ ml: 3, minWidth: 150 }}
              type="button"
              onClick={() => onResolvedFeedback(values.id, values.feedback)}
            >
              SEND EMAIL
            </MDButton>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default ContactSupportForm;

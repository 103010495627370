/* eslint-disable class-methods-use-this */
import client from "./client";

class CommonService {
  getModelList(model, params, cancelToken) {
    return client.request({
      method: "get",
      url: `${model}`,
      params,
      cancelToken,
    });
  }

  updateModel(model, data) {
    console.log(data);
    return client.request({
      method: "put",
      url: model,
      data: JSON.stringify(data),
      responseType: "json",
    });
  }

  deleteModel(model, id) {
    return client.request({
      method: "delete",
      url: model,
      responseType: "json",
    });
  }

  postFile(model, data) {
    return client.request({
      method: "post",
      url: `${model}`,
      data,
      responseType: "json",
    });
  }

  postModel(model, data) {
    return client.request({
      method: "post",
      url: `${model}`,
      data: JSON.stringify(data),
      responseType: "json",
    });
  }

  patchModel(model, data) {
    return client.request({
      method: "patch",
      url: `${model}`,
      data: JSON.stringify(data),
      responseType: "json",
    });
  }

  uploadFile(file, type) {
    // type is: image | video | svg | pdf
    const formData = new FormData();
    formData.set("file", file);
    formData.set("type", type);
    return client.post("/common/upload-file", formData);
  }

  uploadCsv(file, type) {
    // type is: image | video | svg | pdf
    const formData = new FormData();
    formData.set("file", file);
    formData.set("type", type);
    return client.post("/common/upload-file", formData);
  }
}
export default new CommonService();

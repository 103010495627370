import { buyFee } from "service/web3";
import CommonService from "./CommonService";
import client from "./client";

export const listServiceFee = (params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
  };

  if (params.sort && params.sortField) {
    searchParam.sort_by = params.sort;
    searchParam.order_by = params.sortField;
  }
  return CommonService.getModelList("/cms/service-fee", new URLSearchParams(searchParam));
};

export const updateServiceFee = (id, data) => {
  data.service_value =
    data.service_value == "00" ||
    data.service_value == "00.000" ||
    data.service_value == "00.00" ||
    data.service_value == "00.0" ||
    data.service_value == "0.000" ||
    data.service_value == "0.00" ||
    data.service_value == "0.0"
      ? "0"
      : data.service_value;
  // if (data.transaction_type == "Buy") buyFee(data.service_value);

  return CommonService.updateModel(`/cms/service-fee/update/${id}`, data);
};

export const getServiceFeeDetail = (id) => {
  return client.get(`/cms/service-fee/detail/${id}`);
};

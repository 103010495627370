import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import LegalDocumentForm from "layouts/legalDocument/LegalDocumentForm";
import { getLegalDocument, updateLegalDocument } from "service/api/legalDocument";
import { Validate } from "commons/tValidate";

export default function LegalDocumentPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    legalDocument: {
      privacy_policy: "",
      term_and_condition: "",
    },
  });
  const { legalDocument } = getState;
  const onSave = useCallback(
    (request) => {
      return updateLegalDocument(1, request);
    },
    [1]
  );

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_022));
    navigate(Path.legalDocument);
  }, [dispatch]);
  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading) {
      return cleanup;
    }
    getLegalDocument(1)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, legalDocument: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError("NoExist"));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.legalDocument, { state: location });
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return (
    <LegalDocumentForm
      title="Legal Document Management"
      initialValues={legalDocument}
      onSave={onSave}
      onSuccess={onSuccess}
    />
  );
}

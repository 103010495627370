import Web3 from "web3";

const abi = [
  // Your ABI here
];
const contractAddress = "YOUR_CONTRACT_ADDRESS";
const web3 = new Web3("https://mainnet.infura.io/v3/YOUR_INFURA_API_KEY");

export const buyFee = async (newBuyFee) => {
  const contract = new web3.eth.Contract(abi, contractAddress);

  try {
    const accounts = await web3.eth.getAccounts(); // Get the user's account

    // Replace 'setBuyFee' with the actual function in your smart contract
    const transaction = await contract.methods.setBuyFee(newBuyFee).send({
      from: accounts[0], // The user's account
      gas: 50000, // Adjust gas limit as needed
    });

    console.log("Transaction Hash:", transaction.transactionHash);
  } catch (error) {
    console.error("Error setting buy fee:", error.message);
  }
};

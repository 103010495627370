/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControlLabel, Paper, Switch, TextField, Typography } from "@mui/material";
import { showError } from "../../redux/commonReducer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import styled from "@emotion/styled";
import MDButton from "components/MDButton";
import RequiredAsterisk from "components/RequiredAsterisk";
// import DropzoneComponent from "components/DropzoneComponent";
import { Validate } from "commons/tValidate";
// import moment from "moment";

const convertData = (values) => {
  return {
    ...values,
    status: values.status ? 1 : 0,
  };
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export default function FaqForm({
  readonly,
  title,
  initialValues,
  onSave,
  onSuccess,
  isAddScreen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const navigateBack = useNavigateBack();
  const [submitState, setSubmitState] = useState({
    loading: false,
    values: undefined,
  });

  const validationSchema = Yup.object().shape({
    question: Yup.string().trim().required(Validate.MSG_001).max(1000, Validate.MSG_039(1000)),
    answer: Yup.string().trim().required(Validate.MSG_001).max(1000, Validate.MSG_039(1000)),
  });

  const onSubmit = (values) => {
    setSubmitState((v) => ({ ...v, loading: true, values }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { handleSubmit, values, errors, touched, setFieldValue, setFieldError, setSubmitting } =
    formik;

  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!submitState.loading || !submitState.values) {
      return cleanup;
    }
    onSave?.(convertData(submitState.values))
      .then((res) => {
        if (res) {
          setSubmitState((v) => ({ ...v, loading: false }));
          onSuccess();
        }
      })
      .catch((e) => {
        dispatch(showError(Validate.MSG_032));
        setSubmitState((v) => ({ ...v, loading: false }));
      });
    return cleanup;
  }, [
    dispatch,
    onSave,
    onSuccess,
    setFieldError,
    setSubmitting,
    submitState.loading,
    submitState.values,
  ]);
  const moment = require("moment");
  return (
    <DashboardLayout>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" fontWeight="bold" sx={{ wordBreak: "break-word" }}>
          {title}
        </Typography>
        <Box sx={{ xs: 1, sm: 2, md: 3 }} mt={5} mb={5}>
          <Box display="flex" my={3}>
            <Typography width="15%">
              Question
              <RequiredAsterisk />
            </Typography>
            <Box width="80%">
              <TextField
                fullWidth
                InputProps={{ readOnly: readonly }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ maxLength: 1000 }}
                placeholder="Question"
                multiline
                rows={5}
                value={values.question}
                onChange={(e) => setFieldValue("question", e.target.value)}
                error={Boolean(errors?.question && touched?.question)}
                helperText={errors?.question && touched?.question ? errors?.question : ""}
              />
            </Box>
          </Box>
          <Box display="flex" my={3}>
            <Typography width="15%">
              Answer
              <RequiredAsterisk />
            </Typography>
            <Box width="80%">
              <TextField
                fullWidth
                InputProps={{ readOnly: readonly }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ maxLength: 1000 }}
                placeholder="Answer"
                multiline
                rows={5}
                value={values.answer}
                onChange={(e) => setFieldValue("answer", e.target.value)}
                error={Boolean(errors?.answer && touched?.answer)}
                helperText={errors?.answer && touched?.answer ? errors?.answer : ""}
              />
            </Box>
          </Box>

          <Box display="flex" my={3}>
            <Box width="50%" sx={{ display: "flex" }}>
              <Typography width="30%">Status</Typography>
              <Box width="80%">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      readOnly={readonly.status}
                      checked={values.status}
                      onChange={(e) => {
                        if (readonly.status) return;
                        setFieldValue("status", e.target.checked);
                      }}
                    />
                  }
                  sx={{ ml: 1 }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            mt: 2,
          }}
        >
          <MDButton
            type="button"
            variant="outlined"
            color="info"
            sx={{ minWidth: 150 }}
            onClick={() => navigate(-1)}
          >
            Back
          </MDButton>
          {!readonly ? (
            submitState.loading ? (
              <LoadingButton
                loading={submitState.loading}
                type="button"
                variant="contained"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </LoadingButton>
            ) : (
              <MDButton
                type="submit"
                variant="contained"
                color="info"
                sx={{ ml: 3, minWidth: 150 }}
                onClick={() => {}}
              >
                {isAddScreen ? "Add" : "Save"}
              </MDButton>
            )
          ) : (
            ""
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}

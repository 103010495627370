import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

// eslint-disable-next-line react/prop-types
function ConfirmDialog({ title, message, onConfirm, onCancel, confirmText, cancelText, ...rest }) {
  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="info" onClick={onConfirm}>
          {confirmText}
        </MDButton>
        <MDButton
          variant="outlined"
          color="info"
          sx={{ with: "20px" }}
          onClick={onCancel || ((e) => rest.onClose?.(e, "backdropClick"))}
        >
          {cancelText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

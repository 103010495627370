import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Path from "./Path";

export default function UnAuthRoute() {
  const token = useSelector((state) => state.auth.user?.accessToken);
  const location = useLocation();
  if (token && location.pathname === Path.login) {
    return <Navigate to={Path.dashboard} />;
  }
  return <Outlet />;
}

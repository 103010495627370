import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../redux/commonReducer";
import { updateServiceFee } from "service/api/serviceFee";
import { Validate } from "commons/tValidate";
import ServiceFeeDialog from "./ServiceFeeDialog";

function ServiceFeeEditDialog({
  values,
  open,
  onClose,
  setFieldValueItem,
  handleSubmit,
  errors,
  touched,
  loading,
}) {
  const [submitState, setSubmitState] = useState({
    loading: false,
    error: "",
    values: undefined,
    helpers: undefined,
  });

  return (
    <ServiceFeeDialog
      title="Edit Transaction"
      handleSubmit={handleSubmit}
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValueItem}
      submitting={loading}
      onCancel={onClose}
      addText="Save"
      // cancelText="Back"
      open={open}
    />
  );
}

export default ServiceFeeEditDialog;

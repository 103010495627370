import CommonService from "./CommonService";
import client from "./client";

export const list = (params) => {
  const searchParam = {
    page: String(params.page),
    per_page: String(params.perPage),
    search: String(params.keyword),
    status: String(params.status),
  };

  if (params.sort && params.sortField) {
    searchParam.sort_by = params.sort;
    searchParam.order_by = params.sortField;
  }
  return CommonService.getModelList("/cms/faq", new URLSearchParams(searchParam));
};

export const createFaq = (data) => {
  data.question = data.question.trim();
  data.answer = data.answer.trim();
  return CommonService.postModel(`/cms/faq/create`, data);
};

export const updateFaq = (id, data) => {
  data.question = data.question.trim();
  data.answer = data.answer.trim();
  return CommonService.updateModel(`/cms/faq/update/${id}`, data);
};

export const deleteFaq = (id) => {
  return CommonService.deleteModel(`/cms/faq/delete/${id}`);
};

export const getFaqDetail = (id) => {
  return client.get(`/cms/faq/detail/${id}`);
};

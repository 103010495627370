/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Dashboard from "layouts/dashboard";
import AtmMap from "layouts/atmMap";
import AdminUser from "layouts/adminUser";
import Notification from "layouts/notification";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import HexagonIcon from "@mui/icons-material/Hexagon";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
// @mui icons
import Icon from "@mui/material/Icon";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
// import AtmMapAddPage from "layouts/atmMap/AtmMapAddPage";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Path from "./Path";

import AdminUserAddPage from "layouts/adminUser/AdminUserAddPage";
import AdminUserEditPage from "layouts/adminUser/AdminUserEditPage";
import AdminUserDetailPage from "layouts/adminUser/AdminUserDetailPage";

import NotificationAddPage from "layouts/notification/NotificationAddPage";
import NotificationEditPage from "layouts/notification/NotificationEditPage";
import NotificationDetailPage from "layouts/notification/NotificationDetailPage";

// import AtmMapEditPage from "layouts/atmMap/AtmMapEditPage";
// import AtmMapDetailPage from "layouts/atmMap/AtmMapDetailPage";
import Faq from "layouts/Faq";
import FaqAddPage from "layouts/Faq/FaqAddPage";
import FaqEditPage from "layouts/Faq/FaqEditPage";
import FaqDetailPage from "layouts/Faq/FaqDetailPage";
import ServiceFee from "layouts/serviceFee";
import ContactSupport from "layouts/contactSupport";
import ContactSupportDetailPage from "layouts/contactSupport/ContactSupportDetailPage";
import ContactSupportEditPage from "layouts/contactSupport/ContactSupportEditPage";
import Transaction from "layouts/Transaction";
import TransactionsHistoryPageSend from "layouts/Transaction/TransactionsHistoryPageSend";
import TransactionsHistoryPageReceive from "layouts/Transaction/TransactionsHistoryPageReceive";
import TransactionsHistoryPageSwap from "layouts/Transaction/TransactionsHistoryPageSwap";
import TransactionsHistoryPageBuy from "layouts/Transaction/TransactionsHistoryPageBuy";
import TransactionsHistoryPageStake from "layouts/Transaction/TransactionsHistoryPageStake";

import LegalDocumentPage from "layouts/legalDocument/LegalDocumentPage";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
    // component: <Tables />,
  },
  {
    type: "collapse",
    name: "Admin Users",
    key: "admin-user",
    icon: <ManageAccountsIcon />,
    route: "/admin-user",
    component: <AdminUser />,
  },
  {
    key: "admin-user",
    route: Path.adminUserAdd,
    component: <AdminUserAddPage />,
  },
  {
    key: "admin-user",
    route: Path.adminUserEdit(),
    component: <AdminUserEditPage />,
  },
  {
    key: "admin-user",
    route: Path.adminUserDetail(),
    component: <AdminUserDetailPage />,
  },
  {
    type: "collapse",
    name: "Service Fee Dashboard",
    key: "service-fee",
    icon: <HexagonIcon />,
    route: Path.serviceFee,
    component: <ServiceFee />,
  },
  {
    type: "collapse",
    name: "Notification",
    key: "notification",
    icon: <NotificationsIcon />,
    route: "/notification",
    component: <Notification />,
  },
  {
    key: "notification",
    route: Path.notificationAdd,
    component: <NotificationAddPage />,
  },
  {
    key: "notification",
    route: Path.notificationEdit(),
    component: <NotificationEditPage />,
  },
  {
    key: "notification",
    route: Path.notificationDetail(),
    component: <NotificationDetailPage />,
  },

  //atm map management
  // {
  //   type: "collapse",
  //   name: "ATM Map Management",
  //   key: "atm-map",
  //   icon: <CreditCardIcon />,
  //   route: Path.atmMap,
  //   component: <AtmMap />,
  // },
  // {
  //   type: "",
  //   name: "Add new ATM Map Management",
  //   key: "atm-map",
  //   route: Path.atmMapAdd,
  //   component: <AtmMapAddPage />,
  // },
  // {
  //   type: "",
  //   name: "Edit ATM Map Management",
  //   key: "atm-map",
  //   route: Path.atmMapEdit(),
  //   component: <AtmMapEditPage />,
  // },
  // {
  //   type: "",
  //   name: "Detail ATM Map Management",
  //   key: "atm-map",
  //   route: Path.atmMapDetail(),
  //   component: <AtmMapDetailPage />,
  // },
  {
    type: "collapse",
    name: "FAQ Management",
    key: "faq",
    icon: <LiveHelpIcon />,
    route: Path.faq,
    component: <Faq />,
  },
  {
    type: "",
    name: "Add new Management",
    key: "faq",
    route: Path.faqAdd,
    component: <FaqAddPage />,
  },
  {
    type: "",
    name: "Edit FAQ Management",
    key: "faq",
    route: Path.faqEdit(),
    component: <FaqEditPage />,
  },
  {
    type: "",
    name: "FAQ Detail Management",
    key: "faq",
    route: Path.faqDetail(),
    component: <FaqDetailPage />,
  },
  {
    type: "collapse",
    name: "Contact Support",
    key: "contact-support",
    icon: <ConfirmationNumberIcon />,
    route: "/contact-support",
    component: <ContactSupport />,
  },
  {
    key: "contact-support",
    route: Path.contactSupportEdit(),
    component: <ContactSupportEditPage />,
  },
  {
    key: "contact-support",
    route: Path.contactSupportDetail(),
    component: <ContactSupportDetailPage />,
  },
  {
    type: "collapse",
    name: "Legal Document",
    key: "legal-documnent",
    icon: <AssignmentIcon />,
    route: "/legal-documnent",
    component: <LegalDocumentPage />,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <ReceiptLongIcon />,
    route: Path.transactions,
    component: <Transaction />,
  },
  {
    type: "",
    name: "Transactions History Detail",
    key: "transactions",
    route: Path.transactionByWalletAdressSend(),
    component: <TransactionsHistoryPageSend />,
  },
  {
    type: "",
    name: "Transactions History Detail",
    key: "transactions",
    route: Path.transactionByWalletAdressReceive(),
    component: <TransactionsHistoryPageReceive />,
  },
  {
    type: "",
    name: "Transactions History Detail",
    key: "transactions",
    route: Path.transactionByWalletAdressSwap(),
    component: <TransactionsHistoryPageSwap />,
  },
  {
    type: "",
    name: "Transactions History Detail",
    key: "transactions",
    route: Path.transactionByWalletAdressBuy(),
    component: <TransactionsHistoryPageBuy />,
  },
  {
    type: "",
    name: "Transactions History Detail",
    key: "transactions",
    route: Path.transactionByWalletAdressStake(),
    component: <TransactionsHistoryPageStake />,
  },
];

export default routes;

import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box } from "@mui/material";

// eslint-disable-next-line react/prop-types
function NoData({ sx }) {
  //   const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "gray.300",
        gap: 1,
        ...sx,
      }}
    >
      <ErrorOutlineIcon />
      No data available
    </Box>
  );
}

export default NoData;

import axios from "axios";
import store from "../../redux/store";
import { setAuthUser } from "../../redux/authReducer";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

client.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth.user?.accessToken || "";
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    // if (err.response?.status === 400) {
    //   // handle error
    //   return Promise.reject(new Error("Bad Request"));
    // }
    if (originalConfig?.url !== "/user/auth/login" && err.response) {
      /* eslint no-underscore-dangle: 0 */
      if (err.response.status === 401) {
        originalConfig._retry = true;
        try {
          const data = {
            refreshToken: store.getState()?.auth.user?.refreshToken || "",
          };
          if (data) {
            const res = await client.post("/admin/refresh-token", JSON.stringify(data));
            const user = res.data;
            store.dispatch(setAuthUser(user));
            return client(originalConfig);
          }
        } catch (_error) {
          store.dispatch(setAuthUser(null));
          window.location.href = "/login";
        }
      }
    }

    // if (err.response.status === 403) {
    //   // handle error
    //   return Promise.reject(new Error("Forbidden"));
    // }
    const listErrorMess = ["NOT_FOUND"];
    if (err?.response?.status === 404 && listErrorMess.includes(err?.response?.data?.message)) {
      // handle error
      window.location.href = "/notFound";
    }
    return Promise.reject(err);
  }
);

export default client;

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "../../redux/commonReducer";
import PageLoader from "components/PageLoader";
import Path from "route/Path";
import NotificationForm from "./NotificationForm";
import { updateNotification } from "service/api/notification";
import { PUBLIC_STATUS } from "commons/const";
import { getNotificationDetail } from "service/api/notification";
import { Validate } from "commons/tValidate";

export default function NotificationEditPage() {
  const dispatch = useDispatch();
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const [getState, setGetState] = useState({
    loading: true,
    error: "",
    notification: {
      name: "",
      email: "",
      status: PUBLIC_STATUS,
      role: "admin",
    },
  });
  const { notification } = getState;

  const onSave = useCallback(
    (request) => {
      return updateNotification(notificationId, request);
    },
    [notificationId]
  );

  const onSuccess = useCallback(() => {
    dispatch(showSuccess(Validate.MSG_022));
    navigate(Path.notification);
  }, [dispatch]);

  // Get data
  useEffect(() => {
    let mounted = true;
    const cleanup = () => {
      mounted = false;
    };
    if (!getState.loading || !notificationId) {
      return cleanup;
    }
    getNotificationDetail(notificationId)
      .then((res) => {
        const data = res.data.data;
        if (!mounted) return;
        setGetState((v) => ({ ...v, loading: false, notification: data }));
      })
      .catch((e) => {
        if (!mounted) return;
        if (e.response?.status === 400 || e.response?.status === 404) {
          dispatch(showError("NoExist"));
          setGetState((v) => ({ ...v, loading: false }));
          navigate(Path.notification, { state: location });
          return;
        }
        setGetState((v) => ({ ...v, loading: false, error: e.message }));
      });
    return cleanup;
  }, [dispatch, getState.loading, notificationId]);

  if (getState.loading) {
    return <PageLoader />;
  }

  return (
    <NotificationForm
      title="Edit Notification"
      initialValues={{ ...notification, status: notification.status == 1 ? true : false }}
      onSave={onSave}
      onSuccess={onSuccess}
      isAddScreen={false}
    />
  );
}

import { Button, Box, Typography } from "@mui/material";
import { FLEX_COL_CENTER_CENTER } from "theme/themeConst";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// eslint-disable-next-line react/prop-types
function SpanArrow({ orderBy }) {
  return (
    <Box sx={{ ...FLEX_COL_CENTER_CENTER, pl: 1 }}>
      <KeyboardArrowUpIcon
        sx={{
          fontSize: 20,
          mb: -0.5,
          color: orderBy === "asc" ? "inherit" : "gray.300",
        }}
      />
      <KeyboardArrowDownIcon
        sx={{
          fontSize: 20,
          mt: -0.5,
          color: orderBy === "desc" ? "inherit" : "gray.300",
        }}
      />
    </Box>
  );
}

// eslint-disable-next-line react/prop-types
function HeaderSorted({ valueSortBy, valueOrderBy, label, sortBy, handleChangeSorted }) {
  return (
    <Button
      variant="text"
      type="button"
      sx={valueSortBy !== sortBy || !valueOrderBy ? { color: "inherit" } : {}}
      style={{ textTransform: "none", fontSize: "16px" }}
      onClick={() => handleChangeSorted(sortBy)}
    >
      <Typography variant="body2" sx={{ fontWeight: 800 }}>
        {label}
      </Typography>
      <SpanArrow orderBy={valueSortBy === sortBy ? valueOrderBy : ""} />
    </Button>
  );
}

export default HeaderSorted;
